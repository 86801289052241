export const BOX_PREVIEWS = [
  {
    title: 'Forskning',
    content:
      'Forskningen om Kroppskunnskaping har pågått siden 2000 og belyser menneskers helsefremmende ressurser ved kronisk sykdom og hvordan disse kan styrkes...',
    link: '/forskning',
  },
  {
    title: 'Utdanning',
    content:
      'Videreutdanningen i Kroppskunnskaping gir kompetanse og verktøy til å arbeide med helsefremming i møte med personer som lever med helseplager.',
    link: '/utdanning',
  },
  {
    title: 'Helsetilbud',
    content:
      'Forskningen har bidratt til utvikling av et nytt pedagogisk helsetilbud som har vist seg å være nyttig for ulike pasientgrupper...',
    link: '/helsetilbud',
  },
];
