import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { BOX_PREVIEWS } from './consts';

@Component({
  selector: 'app-front-page',
  templateUrl: './front-page.component.html',
  styleUrls: ['./front-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FrontPageComponent {
  previews = BOX_PREVIEWS;
}
