import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BestillingsskjemaComponent } from './bestillingsskjema/bestillingsskjema.component';
import { FrontPageComponent } from './front-page/front-page.component';
import { KontaktComponent } from './kontakt/kontakt.component';
import { ContentContainerModule } from './shared/content-container/content-container.module';
import { PreviewBoxModule } from './front-page/preview-box/preview-box.module';
@NgModule({
  declarations: [AppComponent, FrontPageComponent, BestillingsskjemaComponent, KontaktComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    RouterModule,
    MatButtonModule,
    PreviewBoxModule,
    ContentContainerModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
