import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BestillingsskjemaComponent } from './bestillingsskjema/bestillingsskjema.component';
import { FrontPageComponent } from './front-page/front-page.component';
import { KontaktComponent } from './kontakt/kontakt.component';

const routes: Routes = [
  {
    path: '',
    component: FrontPageComponent,
    pathMatch: 'full',
  },
  {
    path: 'forskning',
    loadChildren: () => import('./forskning/forskning.module').then((m) => m.ForskningModule),
  },
  {
    path: 'utdanning',
    loadChildren: () => import('./utdanning/utdanning.module').then((m) => m.UtdanningModule),
  },
  {
    path: 'helsetilbud',
    loadChildren: () => import('./helsetilbud/helsetilbud.module').then((m) => m.HelsetilbudModule),
  },

  {
    path: 'bakgrunn',
    loadChildren: () => import('./bakgrunn/bakgrunn.module').then((m) => m.BakgrunnModule),
  },

  {
    path: 'bestillingsskjema',
    component: BestillingsskjemaComponent,
  },
  {
    path: 'kontakt-oss',
    component: KontaktComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
