export const MENU_DATA = [
  {
    id: 1,
    header: 'Forskning',
    routerLink: ['forskning'],
    children: [
      {
        header: 'Publikasjoner',
        routerLink: ['forskning', 'publikasjoner'],
      },
      {
        header: 'Prosjekter',
        routerLink: ['forskning', 'prosjekter'],
      },
      {
        header: 'Foredrag',
        routerLink: ['forskning', 'foredrag'],
      },
      {
        header: 'Informasjon',
        routerLink: ['forskning', 'informasjon'],
      },
    ],
  },
  {
    id: 2,
    header: 'Utdanning',
    routerLink: ['utdanning'],
    children: [
      {
        header: 'Studieplan',
        routerLink: ['utdanning', 'studieplan'],
      },
      {
        header: 'Informasjon',
        routerLink: ['utdanning', 'informasjon'],
      },
    ],
  },
  {
    id: 3,
    header: 'Helsetilbud',
    routerLink: ['helsetilbud'],
    children: [
      {
        header: 'Brukere',
        routerLink: ['helsetilbud', 'brukere'],
      },
      {
        header: 'Helsepersonell',
        routerLink: ['helsetilbud', 'helsepersonell'],
      },
      {
        header: 'Informasjon',
        routerLink: ['helsetilbud', 'informasjon'],
      },
    ],
  },
  {
    id: 4,
    header: 'Bakgrunn',
    routerLink: ['bakgrunn'],
    children: [
      {
        header: 'Hva er Kroppskunnskaping',
        routerLink: ['bakgrunn', 'hva-er-kroppskunnskaping'],
      },
      {
        header: 'Omtale',
        routerLink: ['bakgrunn', 'omtale'],
      },
    ],
  },

  {
    id: 5,
    header: 'Bestillingsskjema',
    routerLink: ['bestillingsskjema'],
    children: [],
  },

  {
    id: 6,
    header: 'Kontakt',
    routerLink: ['kontakt-oss'],
    children: [],
  },
];
