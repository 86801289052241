<div class="topbar">
  <img (click)="goTo({ routerLink: ['/'] })" class="logo" style="cursor: pointer" src="/assets/logo.png" alt="brand-logo" />
  <div class="menu">
    <ng-container *ngFor="let menuItem of menuData">
      <ng-container *ngIf="!menuItem.children?.length">
        <button (click)="goTo(menuItem)" mat-button class="nav-link">
          <span class="text-holder">{{ menuItem.header }}</span>
        </button>
      </ng-container>
      <ng-container *ngIf="menuItem?.children?.length">
        <button
          (click)="goTo(menuItem)"
          #button
          mat-button
          [matMenuTriggerFor]="levelOne"
          #levelOneTrigger="matMenuTrigger"
          (mouseenter)="buttonEnter(levelOneTrigger)"
          (mouseleave)="buttonLeave(levelOneTrigger, button)"
          style="z-index: 1050"
        >
          <span>{{ menuItem.header }} </span>
        </button>

        <mat-menu #levelOne="matMenu" direction="down" yPosition="below">
          <span (mouseenter)="menuenter()" (mouseleave)="menuLeave(levelOneTrigger, button)">
            <ng-container *ngFor="let childL1 of menuItem?.children">
              <button class="p-0" *ngIf="!childL1.children" mat-menu-item>
                <a (click)="goTo(childL1)" class="nav-link">{{ childL1.header }} </a>
              </button>
            </ng-container>
          </span>
        </mat-menu>
      </ng-container>
    </ng-container>
  </div>
  <div style="padding: 10px" class="icons">
    <a href="http://bodyknowledging.com"><img alt="english" src="/assets/en.png" /></a>
    <a href="http://facebook.com/kroppskunnskaping"><img alt="fb" src="/assets/fb.png" /></a>
    <a href="mailto:post@kroppskunnskaping.no"><img alt="mail" src="/assets/mail.png" /></a>
  </div>
</div>
<div class="divider"></div>
<div class="content">
  <router-outlet></router-outlet>
</div>
<div class="divider"></div>
<div class="footer">
  <div class="credits">
    Siden levert av
    <a href="http://www.designbasen.no/firma/kreatoriet-article4816-654.html">Kreatoriet</a>
    og <a href="http://lroedal.net">lroedal.net</a>.
  </div>
</div>
