<div class="about">
  <div class="about-content">
    <h1>ET NYTT HELSEKONSEPT</h1>
    <h3>
      <p>Helsefremmende tiltak for å styrke brukeren i å være sjef for egen helse.</p>
    </h3>
    <div class="slide-read-more">
      <h4 style="margin: 0; padding: 5px">
        <a style="color: #fff" routerLink="/bakgrunn/hva-er-kroppskunnskaping">Les mer <span class="glyphicon glyphicon-forward"></span></a>
      </h4>
    </div>
  </div>
</div>
<div class="box-container">
  <span class="filler"></span>
  <app-preview-box
    *ngFor="let preview of previews"
    [title]="preview.title"
    [content]="preview.content"
    [link]="preview.link"
  ></app-preview-box>
  <span class="filler"></span>
</div>
