import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-container',
  template: `
    <ng-container *ngIf="headerTitle">
      <h1>{{ headerTitle }}</h1>
      <hr />
    </ng-container>
    <ng-content></ng-content>
  `,
  styles: [
    `
      app-container {
        display: block;
        padding: 40px 15vw;

        h1 {
          font-size: 36px !important;
        }
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentContainerComponent {
  @Input() headerTitle?: string;
}
