import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-bestillingsskjema',
  template: `
    <iframe
      title="Bestillingsskjema"
      src="https://docs.google.com/forms/d/e/1FAIpQLSfaEA469tGuXZv4c5IZKFGX2zXMWw_kJ6Q4sN-tNsj04n-vXg/viewform?embedded=true"
      width="100%"
      height="auto"
      style="min-height: 100%"
      >Laster inn …</iframe
    >
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BestillingsskjemaComponent {}
