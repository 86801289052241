<app-container headerTitle="Kontakt oss">
  <p>
    For mer informasjon om teorien, modellen, videreutdanningen og helsetilbudet, kontakt:<br /><strong>Dr.polit Kristin Heggdal</strong
    ><br /><a href="mailto:post@kroppskunnskaping.no">post@kroppskunnskaping.no</a><br /><a href="mailto:post@bodyknowledging.com"
      >post@bodyknowledging.com</a
    >
  </p>

  <p>
    For informasjon om erfaringer med gjennomføringen av KK som helsetilbud i praksis, kontakt:<br /><strong
      >Ergoterapeut Anita Fjellheim</strong
    ><br /><a href="mailto:anita.fjellheim@sykehuset-innlandet.no">anita.fjellheim@sykehuset-innlandet.no</a>
  </p>

  <p>
    <strong>Sykepleier Janne Hernes</strong><br /><a href="mailto:janne.hernes@sykehuset-innlandet.no"
      >janne.hernes@sykehuset-innlandet.no</a
    >
  </p>
</app-container>
