import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviewBoxComponent } from './preview-box.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [PreviewBoxComponent],
  exports: [PreviewBoxComponent],
  imports: [CommonModule, RouterModule],
})
export class PreviewBoxModule {}
